import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
     name:'Alicia Vikander',
     age:20,
     dob:'20/08/1990',
     logged: false,
     apiToken:''
    },
    mutations:{
     updateName(state, name){
      state.logged = name
     },
     apiToken(state,apiToken){
        state.apiToken = apiToken
     }
    }
   })