<template>
  <div id="login">
      <h1>Login</h1>
      <input type="text" name="username" v-model="input.username" placeholder="Username" />
      <input type="password" name="password" v-model="input.password" placeholder="Password" />
      <button type="button" v-on:click="login()">Login</button>
  </div>
</template>

<script>
import store from '../store/store'
import axios from 'axios'

export default {
  name: 'Login',

  Comment:{
      store,axios
  },

  data() {
      return {
          input: {
              username: "",
              password: ""
          }
      }
  },

  methods: {
    login: function  (message) {


axios.post( process.env.VUE_APP_API + '/api/login', {
  username: 'al',
  password: 'password'
})
.then((response) => {
    if ((response.status) === 200){
      console.log(response.data.apiToken);
      store.commit('updateName',true);
      store.commit('apiToken',response.data.apiToken);
      this.$router.replace('/');
    }

}, (error) => {
  console.log(error);
});


/*
   axios
      .get('http://localhost:85/api/login')
      .then(response => (this.info = response))

      //store.commit('updateName',true)

      //console.log(store.state.logged) // -> 1
      //this.$router.replace('/');
    }
    */
  }
  }

}
</script>