
import Vue from 'vue';
import Vuex from 'vuex';

import VueRouter from 'vue-router';
import App from './App'
import LoginPage from './components/LoginPage'




function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 

console.log(store.state.logged);

if(store.state.logged){
  next(); // allow to enter route
 }else{
  next('/login'); // go to '/login';
 }
}

//https://mallgroup-it.medium.com/mluvime-spolu-ve-vuejs-cast-druha-3d8c87d4bf31


Vue.use(VueRouter);


import store from './store/store'



import Home from './components/NavBar';
import ProductCatalog from './components/ProductCatalog'

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {       
      path: "/",
    //  name: "home",
        beforeEnter : guardMyroute,
        component: ProductCatalog,
    //  meta: {title: 'Home'} 
    },
    {       
      path: "/login",
    //  name: "home",
    //    beforeEnter : guardMyroute,
        component: LoginPage,
    //  meta: {title: 'Home'} 
    },

    { path: '/mm', component: ProductCatalog,beforeEnter : guardMyroute  },

    { path: '*', redirect: '/login' }
  ]
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})