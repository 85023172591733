<template>
  <p>I'm a product catalog!</p>
</template>


<script>
import axios from 'axios'

export default {

   mounted: function () {
    
    axios.get( process.env.VUE_APP_API + '/api/projects', {
      headers: {
        'token': `${this.$store.state.apiToken}`
      }
    })
    .then((res) => {
      console.log(res.data)
    })
    .catch((error) => {
      console.error(error)
    })



      console.log('mystore',this.$store.state.apiToken) // -> 1
      //console.log('to jede',store.state.name)
    }
}
</script>